<template>
  <v-app id="loading-wrapper">
    <v-main class="page">
      <v-container fluid>
        <transition>
          <LoginPage></LoginPage>
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { refreshToken } from './services/authentication-legacy'
import LoginPage from './Login/Login.vue'

const LocalStorageToken = 'teamculture:legacy'
const LocalStorageTokenSecret = 'teamculture:legacy:secret'

export default {
  name: 'App',

  components: {
    LoginPage,
  },

  methods: {
    calcLanguageToLocale(lang) {
      const D_LANGUAGE = 'pt'

      if (!lang) {
        return D_LANGUAGE
      }

      const language = lang?.match(/en(-US)?|es(-AR)?/i)
        ? lang.slice(0, 2)
        : D_LANGUAGE

      return language
    },

    saveLanguage(lang) {
      const languageBySession = localStorage.getItem(
        'interface-preferences--language',
      )

      const shouldGetSessionLanguage = !lang && languageBySession
      const shouldGetNavigatorLanguage = !lang && !languageBySession
      let reloadByNotEqSession = false

      if (shouldGetSessionLanguage) {
        lang = languageBySession
      }

      if (shouldGetNavigatorLanguage) {
        lang = this.calcLanguageToLocale(
          navigator.language || navigator.userLanguage,
        )
      }

      if (languageBySession !== lang && !shouldGetNavigatorLanguage) {
        reloadByNotEqSession = true
      }

      return { language: lang, reloadByNotEqSession }
    },

    async handleChangeLanguage(lang) {
      const { language } = this.saveLanguage(lang)
      this.$root.$i18n.locale = language
    },

    handleTokenCookie(tokenKey) {
      const cookies = decodeURIComponent(document.cookie).split(';')

      for (const cookie of cookies) {
        const trimmedCookie = cookie.trim()

        if (trimmedCookie?.startsWith(tokenKey)) {
          return trimmedCookie?.substring(tokenKey.length)
        }
      }

      return ''
    },

    logout() {
      localStorage.clear()

      let logoutUrl = `https://app.teamculture.com.br/logout`
      if (window.location.pathname.includes('/survey')) {
        logoutUrl += `?redirectUrl=${encodeURIComponent(
          window.location.pathname,
        )}`
      }

      window.location.assign(logoutUrl)
    },

    async fetchToken(tokenCookie) {
      // 1. token from cookie
      if (tokenCookie) {
        console.log(`Legacy token from cookie ${'tokenCookie'}`)

        localStorage.setItem(LocalStorageToken, tokenCookie)

        return tokenCookie
      }

      // 2. token from local storage
      const legacyToken = localStorage.getItem(LocalStorageToken)
      if (legacyToken) {
        console.log(`Legacy token from localStorage ${'legacyToken'}`)

        return legacyToken
      }

      // 3. token not found redirect to login
      console.log('Token not found')
    },

    removeFirebaseAuthentication() {
      const keys = Object.keys(localStorage)
      const firebaseKey = keys.find(key => key.includes('firebase:authUser'))
      if (firebaseKey) {
        console.log('firebaseKey removed')
        localStorage.removeItem(firebaseKey)
      }
    },
  },

  created() {
    const urlParams = new URLSearchParams(window.location.search)
    const queryLang = urlParams.get('l')

    this.handleChangeLanguage(queryLang)
  },

  async mounted() {
    console.log('App mounted')

    const tokenCookie = this.handleTokenCookie('t=')
    const personSecretCookie = this.handleTokenCookie('p=')

    this.removeFirebaseAuthentication()

    const token = await this.fetchToken(tokenCookie)

    if (!token) {
      return this.logout()
    }

    if (personSecretCookie) {
      localStorage.setItem(LocalStorageTokenSecret, personSecretCookie)
    }

    const personSecret = localStorage.getItem(LocalStorageTokenSecret)
    console.log(`Process refresh token ${'personSecret'}`)

    try {
      const newToken = await refreshToken(token, personSecret)

      console.log(`Refresh token ${'newToken'}`)

      localStorage.setItem(LocalStorageToken, newToken)
    } catch (e) {
      console.log('refresh-token error', e)
      this.logout()
    }
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
