<template>
  <div id="auth-page">
    <div class="auth">
      <div class="auth-form">
        <div class="login-page">
          <div class="login-loading">
            <v-progress-circular
              color="primary"
              :size="50"
              indeterminate
            ></v-progress-circular>

            <div class="login-loading-container">
              <p
                class="login-loading-text"
                v-text="$t('pageLogin.loading.text')"
              ></p>
              <span
                class="login-loading-description"
                v-text="$t('pageLogin.loading.description')"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginPage',
  computed: {
    _isMobile() {
      return this.$vuetify?.breakpoint?.smAndDown
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped />
