var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "auth-page" } }, [
    _c("div", { staticClass: "auth" }, [
      _c("div", { staticClass: "auth-form" }, [
        _c("div", { staticClass: "login-page" }, [
          _c(
            "div",
            { staticClass: "login-loading" },
            [
              _c("v-progress-circular", {
                attrs: { color: "primary", size: 50, indeterminate: "" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "login-loading-container" }, [
                _c("p", {
                  staticClass: "login-loading-text",
                  domProps: {
                    textContent: _vm._s(_vm.$t("pageLogin.loading.text")),
                  },
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "login-loading-description",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("pageLogin.loading.description")
                    ),
                  },
                }),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }