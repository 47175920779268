import Vue from 'vue'

import Vuetify from 'vuetify/lib'

import en from 'vuetify/lib/locale/en'
import es from 'vuetify/lib/locale/es'
import pt from 'vuetify/lib/locale/pt'

Vue.use(Vuetify)

/** @type {import('vuetify/types').UserVuetifyPreset} */
export const options = {
  rtl: false,
  lang: {
    locales: {
      en,
      es,
      pt,
    },
    current: 'pt',
  },
  breakpoint: {
    thresholds: {
      xs: 515,
      sm: 768,
      md: 992,
      lg: 1280,
    },
    scrollBarWidth: 10,
  },
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#dd0041',
        secondary: '#fdcc4a',
        accent: '#ecb2f2',
        error: '#da1414',
        info: '#2e5aac',
        success: '#287d3c',
        warning: '#b95000',
      },
    },
  },
}

Vuetify.config.silent = true

export default new Vuetify(options)
