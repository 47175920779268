import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'

import i18n from './i18n'
import vuetify from './vuetify'

import App from './App.vue'
import './main.scss'

const LoadingParcel = singleSpaVue({
  Vue,
  appOptions: {
    render: h => h(App),
    vuetify,
    i18n,
  },
})

const transition_unmount = () => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 1000)
  })
}

export default {
  ...LoadingParcel,
  unmount: [transition_unmount, LoadingParcel.unmount],
}
