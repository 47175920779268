import {
  LOAD_ERROR,
  addErrorHandler,
  getAppStatus,
  registerApplication,
  start,
} from 'single-spa'

import {
  constructApplications,
  constructLayoutEngine,
  constructRoutes,
} from 'single-spa-layout'

import microfrontendLayout from './microfrontend-layout.html'
import './teamculture-root.css'

import LoadingParcel from './WrapperLoading/main.js'

const layoutData = {
  props: {},
  loaders: {
    appLoader: LoadingParcel,
  },
}

const routes = constructRoutes(microfrontendLayout, layoutData)

const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name)
  },
})

const layoutEngine = constructLayoutEngine({
  routes,
  applications,
})

applications.forEach(registerApplication)

layoutEngine.activate()

addErrorHandler(err => {
  if (getAppStatus(err.appOrParcelName) === LOAD_ERROR) {
    System.delete(System.resolve(err.appOrParcelName))
  }
})

start()
